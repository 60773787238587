<template>
  <section id="app">
    <header class="d-flex justify-content-between">
      <!-- redes -->
      <div class="p-2">
        <!-- Whatsapp -->
        <a
          aria-label="Icono de Whatsapp"
          href="https://api.whatsapp.com/message/DFXAEG33VKIZH1?autoload=1&app_absent=0"
          target="_blank"
          rel="noopener"
          class="btn btn-outline-light btn-floating border border-light"
          ><i class="fa-brands fa-whatsapp"></i
        ><p class="d-none d-inline comment">Whatsapp</p></a>

        <!-- Instagram -->
        <a
          aria-label="Icono de Instagram"
          href="https://www.instagram.com/orbita.com.co/"
          target="_blank"
          rel="noopener"
          class="btn btn-outline-light btn-floating border border-light mx-1"
          ><i class="fa-brands fa-instagram"></i
        ><p class="d-none d-inline comment">Instagram</p></a>

        <!-- Linkedin -->
        <a
          aria-label="Icono de Linkedin"
          href="https://www.linkedin.com/company/iorbita/"
          target="_blank"
          rel="noopener"
          class="btn btn-outline-light btn-floating border border-light mx-1"
          ><i class="fa-brands fa-linkedin"></i
        ><p class="d-none d-inline comment">Linkedin</p></a>

        <!-- youtube -->
        <a
          aria-label="Icono de youtube"
          href="https://youtube.com/@DesarrollandoElFuturo?si=0gOr3DwEAEQqwqqy"
          target="_blank"
          rel="noopener"
          class="btn btn-outline-light btn-floating border border-light mx-1"

        >
          <i class="fa-brands fa-youtube"></i
        ><p class="d-none d-inline comment">youtube</p></a>
      </div>

      <!-- logo -->
      <div class="">
        <!-- escritorio -->
        <img v-if="$route.path !== '/'" class="logo-orbita d-none d-sm-none d-md-block"
          src="https://static.orbita.com.co/public/general/images/Logo_Amarillo_Orbita.png" alt="logo orbita amarillo" />
        <!-- Movil -->
        <img v-if="$route.path !== '/'" class="logo-orbita d-block d-sm-block d-md-none"
          src="https://static.orbita.com.co/public/general/images/Icono_Amarillo_Orbita.png" alt="logo orbita amarillo" />
      </div>
    </header>

    <main>
      <!-- contenido -->
      <div class="card-general">
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </div>

      <!-- Navegación con router-link -->
      <div class="d-flex justify-content-center pt-lg-2 pt-1">
        <div class="d-flex justify-content-center gap-0 lg:gap-3 pt-lg-2">
          <!-- Inicio -->
          <router-link to="/" exact>
            <button id="btnInicio" aria-label="Inicio"
              :class="['btn', 'button', 'rounded-pill', 'col-6', 'col-sm-3', 'col-lg-2', isActive('/') ? 'active' : '']">
              <span class="d-none d-md-inline">Inicio</span>
              <i class="d-md-none bi bi-house"></i>
            </button>
          </router-link>

          <!-- Quienes Somos -->
          <router-link to="/quienes-somos">
            <button id="btnQuieness" aria-label="¿Quienes Somos?"
              :class="['btn', 'button', 'rounded-pill', 'col-6', 'col-sm-3', 'col-md-2', 'col-lg-2', isActive('/quienes-somos') ? 'active' : '']">
              <span class="d-none d-md-inline texto">¿Quienes Somos?</span>
              <i class="d-md-none bi bi-file-earmark-person"></i>
            </button>
          </router-link>

          <!-- Servicios -->
          <router-link to="/servicios">
            <button id="btnServicios" aria-label="Servicios"
              :class="['btn', 'button', 'rounded-pill', 'col-6', 'col-sm-3', 'col-md-2', 'col-lg-2', isActive('/servicios') ? 'active' : '']">
              <span class="d-none d-md-inline">Servicios</span>
              <i class="d-md-none bi bi-card-checklist"></i>
            </button>
          </router-link>

          <!-- Planes -->
          <router-link to="/planes">
            <button id="btnPlanes" aria-label="Planes"
              :class="['btn', 'button', 'rounded-pill', 'col-6', 'col-sm-3', 'col-md-2', 'col-lg-4', isActive('/planes') ? 'active' : '']">
              <span class="d-none d-md-inline">Planes</span>
              <i class="d-md-none bi bi-cart"></i>
            </button>
          </router-link>

          <!-- Equipo -->
          <router-link to="/equipo">
            <button id="btnEquipo" aria-label="Equipo"
              :class="['btn', 'button', 'rounded-pill', 'col-6', 'col-sm-3', 'col-md-2', 'col-lg-4', isActive('/equipo') ? 'active' : '']">
              <span class="d-none d-md-inline">Equipo</span>
              <i class="d-md-none bi bi-person-heart"></i>
            </button>
          </router-link>

          <!-- Contactanos -->
          <router-link to="/contactanos">
            <button id="btnContactanos" aria-label="Contactanos"
              :class="['btn', 'button', 'rounded-pill', 'col-6', 'col-sm-3', 'col-md-2', 'col-lg-2', isActive('/contactanos') ? 'active' : '']">
              <span class="d-none d-md-inline">Contactanos</span>
              <i class="d-md-none bi bi-phone"></i>
            </button>
          </router-link>
        </div>
      </div>
    </main>

    <footer>
      <div class="d-flex justify-content-center align-content-center">
        <p class="d-flex justify-content-center pt-2">
          ©2020 al 2024 Copyright <a href="#" class="pt-0 mt-0 p-1"> www.orbita.com.co</a>
        </p>
      </div>
    </footer>
  </section>
</template>

<script setup>
import { useRoute } from 'vue-router';
const route = useRoute();
const isActive = (path) => {
  return route.path === path;
};
</script>

<style scoped>

button {
  height: 60px;
  width: 165px;
  color: #F6F7F9;
  font-weight: bold;
  border: 1px solid rgba(255, 255, 255, 0.3); /* Borde semi-transparente */
  background: rgba(255, 255, 255, 0.1); /* Fondo semi-transparente */
  backdrop-filter: blur(10px); /* Desenfoque del fondo */
  -webkit-backdrop-filter: blur(10px); /* Desenfoque para Safari */
  border-radius: 16px; /* Bordes redondeados */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Sombra suave */
  transition: all 0.3s ease; /* Transición suave */
  transition: transform 0.9s;
}

button:hover {
  border-color: #FFCC01; /* Borde amarillo en hover */
  color: #FFCC01; /* Texto amarillo en hover */
  font-size: 17px;
  border-width: 2px;
  padding: 0%;
  transform: translateY(-5px); /* Ligeramente arriba en hover */
}

button.active {
  transform: translateY(-15px);
  border-color: #FFCC01;
  color: #FFCC01;
  font-size: 17px;
  border-width: 2px;
  padding: 0%;
  background: rgba(255, 255, 255, 0.2); /* Fondo más brillante cuando está activo */
}

.card-general {
  height: calc(80vh - 80px);
  background: rgba(255, 255, 255, 0.1); /* Fondo semi-transparente */
  backdrop-filter: blur(10px); /* Desenfoque del fondo */
  -webkit-backdrop-filter: blur(10px); /* Desenfoque para Safari */
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Sombra suave */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Borde semi-transparente */
  color: #fcfafa;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scrollbar-color: transparent transparent;
  transition: all 0.3s ease;
}

.card-general::-webkit-scrollbar {
  display: none;
}

.logo-orbita {
  height: 60px;
  position: relative;
  top: 35px;
  left: -35px;
  z-index: 1000;
}

/* Móvil */
@media screen and (max-width: 767px) and (max-width: 767px) {
  a {
    border: none !important;
    border-radius: -46px;
    margin: 3px;
  }

  i {
    font-size: 20px;
  }

  /* Botones */
  button {
    border-radius: 46px !important;
    width: 47px;
    height: 50px;
  }
}

@media screen and (min-width: 600px) {
  .card-general {
    height: calc(80vh - 20px);
  }
}

@media (min-width: 601px) and (max-width: 1279px) {

  a {
    border: none !important;
    border-radius: -46px;
    margin: -5px;
  }
}

/* Portátil */
@media screen and (min-width: 1280px) and (max-width: 1919px) {
  a {
    border: none !important;
  }
}

/* Escritorio */
@media screen and (min-width: 1920px) {
  a {
    border: none !important;
    border-radius: -46px;
    margin: 5px;
  }

  i {
    font-size: 20px;
  }
}
</style>
