<template>
    <section class="d-flex flex-wrap align-items-center">
        <div class="col-lg-5">
            <div class="d-block d-sm-block d-md-none p-4">
                <h1 class="title-contact">Contactanos</h1>
                <h3 class="subtitle">Estas a unos pasos de impulsar tu negocio.</h3>
            </div>

            <div class="d-flex justify-content-center align-items-center">
                <img src="https://static.orbita.com.co/public/general/images/Orbita_Contactanos.png"
                    alt="imagen con morado y amarillo" width="300" />
            </div>

            <div class="d-flex flex-wrap flex-column my-3 d-none d-sm-none d-md-block">
                <div class="d-flex justify-content-center align-content-center">
                    <i class="fa-solid fa-phone p-1 mx-2"></i>
                    <p> +57 322 812 1882</p>
                </div>

                <div class="d-flex justify-content-center align-content-center">
                    <i class="fa-solid fa-envelope p-1 mx-2"></i>
                    <p>conecta@orbita.com.co</p>
                </div>

                <div class="d-flex justify-content-center align-content-center">
                    <i class="fa-solid fa-address-card p-1 mx-2"></i>
                    <p>Colombia, Tolima Ibague</p>
                </div>

                <div class="d-flex justify-content-center align-content-center">
                    <i class=" fa-solid fa-house p-1 mx-2"></i>
                    <p>Cra 22 a Sur # 154 - 120</p>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="p-3 d-none d-sm-none d-md-block">
                <h1 class="title-contact">Contactanos</h1>
                <p class="subtitle">Estas a unos pasos de impulsar tu negocio.</p>
            </div>

            <form class="card p-4" v-bind:class="{ 'loading': isLoading }">
                <div class="my-2">
                    <label>Nombres y Apellidos</label>
                    <input v-model="contacto.username" type="text" id="nombresApellidos" required
                        placeholder="Nombres y Apellidos" class="form-control" />
                </div>

                <div class="row">
                    <div class="col-md-8 my-2">
                        <label>Correo</label>
                        <input v-model="contacto.from" type="email" id="correo" required placeholder="Email@email.com"
                            class="form-control" />
                    </div>

                    <div class="col-md-4 my-2">
                        <label>Teléfono</label>
                        <input v-model="contacto.phone" type="text" id="telefono" required placeholder="310 0000 000"
                            class="form-control" oninput="this.value = this.value.replace(/[^0-9]/g, '')" />
                    </div>
                </div>

                <div class="my-2">
                    <label>Mensaje</label>
                    <textarea class="form-control" placeholder="Tu Mensaje" v-model="contacto.content" required
                        id="mensaje"></textarea>
                </div>


                <div class="loading-overlay" v-if="isLoading">
                    <img src="https://static.orbita.com.co/public/general/images/Icono_A_Color_Orbita.png" alt="Cargando" class="custom-spinner" />
                </div>

                <div class="d-flex justify-content-between align-content-center">
                    <div>
                        <p class="m-0 p-0" style="font-size: 10px;">Al marcar esta casilla, acepto las políticas de
                            privacidad de la página web. Entiendo y consiento el uso de mis datos personales de acuerdo
                            con
                            estas políticas. Para más detalles, puedes consultar nuestras políticas de privacidad</p>
                        <div class="m-1">
                            <input class="form-check-input custom-checkbox mx-1" type="checkbox" value=""
                                v-model="contacto.politicas" id="flexCheckDefault">
                            <label class="form-check-label" for="flexCheckDefault">Acepta políticas de
                                privacidad</label>
                        </div>
                    </div>

                    <div>
                        <button type="button" class="btn btn-secondary mt-3 mx-1" v-on:click="enviar"
                            v-bind:disabled="!isFormValid">ENVIAR</button>
                    </div>
                </div>
            </form>

            <div class="d-flex flex-wrap flex-column my-3 d-sm-block d-md-none">
                <div class="d-flex justify-content-center align-content-center">
                    <i class="fa-solid fa-phone p-1 mx-2"></i>
                    <p> +57 322 812 61882</p>
                </div>

                <div class="d-flex justify-content-center align-content-center">
                    <i class="fa-solid fa-envelope p-1 mx-2"></i>
                    <p>conecta@orbita.com.co</p>
                </div>

                <div class="d-flex justify-content-center align-content-center">
                    <i class="fa-solid fa-address-card p-1 mx-2"></i>
                    <p>Colombia, Tolima Ibague</p>
                </div>

                <div class="d-flex justify-content-center align-content-center">
                    <i class="fa-solid fa-house p-1 mx-2"></i>
                    <p>Cra 22 a Sur # 154 - 120</p>
                </div>

            </div>
        </div>

        <!-- Mensaje -->
        <div class="alert-container">
            <div class="alert" v-bind:class="alertClass" v-if="alertMessage">
                {{ alertMessage }}
            </div>
        </div>

    </section>
</template>

<script>
import axios from 'axios';

export default {
    name: 'OrbitaContactanos',
    data() {
        return {
            contacto: {
                username: '',
                from: '',
                phone: '',
                content: '',
                politicas: false,
                subject: 'Contactame'
            },
            isLoading: false,
            alertMessage: '',
            alertClass: '',
        }
    },
    computed: {
        isFormValid() {
            return (
                this.contacto.username !== '' &&
                this.contacto.from !== '' &&
                this.contacto.phone !== '' &&
                this.contacto.content !== '' &&
                this.contacto.subject !== '' &&
                this.contacto.politicas
            );
        }
    },
    methods: {
        enviar() {
            this.isLoading = true;
            axios.post("https://api.orbita.com.co/v1/api/orbita/utilidades/smtp", this.contacto, {
                responseType: 'text'
            }).then(response => {
                console.log(response.data);
                this.alertMessage = 'Mensaje enviado con éxito.';
                this.alertClass = 'alert-success';
            }).catch(error => {
                console.error(error);
                this.alertMessage = 'Error al enviar el mensaje. Por favor, inténtalo de nuevo más tarde.';
                this.alertClass = 'alert-danger';
            }).finally(() => {
                this.limpiar();
                this.isLoading = false;
                setTimeout(() => {
                    this.alertMessage = '';
                    this.alertClass = '';
                }, 3000);
            });
        },
        limpiar() {
            this.contacto.username = '';
            this.contacto.from = '';
            this.contacto.phone = '';
            this.contacto.content = '';
            this.contacto.politicas = false;
        }
    }
}
</script>

<style scoped>
.card {
    background: rgba(25, 1, 63, 0.7); /* Fondo transparente para glassmorfismo */
  backdrop-filter: blur(10px); /* Efecto borroso de glassmorfismo */
  -webkit-backdrop-filter: blur(10px);
    border: 1px solid #8f09c8;
    border-radius: 33px;
    margin: 10px;
    color: aliceblue;
}

h1 {
    font-size: 70px;
}

h3 {
    font-size: 30px;
}

.btn-secondary {
    color: #FFCC01;
    background-color: #19013F;
    border: 1px solid #FFCC01;
    font-family: "TT Hazelnut";
}

.btn-secondary:hover {
    background-color: #FFCC01;
    color: #19013F;
    border: 1px solid #19013F;
}

.custom-checkbox:checked {
    background-color: #19013F;
    border-color: #FFCC01;
}

.custom-checkbox:checked::before {
    background-color: #FFCC01;
}

/* Móvil */
@media screen and (max-width: 624px) {
    h1 {
        font-size: 45PX;
    }

    h3 {
        font-size: 25PX;
    }

}

.loading {
    position: relative;
}

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.custom-spinner {
    width: 50px; 
    height: 60px; 
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.alert-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    pointer-events: none;
    padding-top: 45px;
}

.alert {
    width: 80%;
    max-width: 500px;
    text-align: center;
    padding: 10px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: bold;
    pointer-events: auto;
}

.alert-success {
    background-color: #dff0d8;
    border-color: #d0e9c6;
    color: #3c763d;
}

.alert-danger {
    background-color: #f2dede;
    border-color: #ebccd1;
    color: #a94442;
}
</style>