<template>
  <div>
    <!-- Sección de Inicio -->
    <section
      class="d-flex flex-wrap justify-content-center px-5 py-sm-3 align-items-lg-center"
    >
      <div class="d-flex justify-content-center pt-4">
        <img
          class="logo-orbita"
          src="https://static.orbita.com.co/public/general/images/Logo_Amarillo_Orbita.png"
          alt="LogoAmarilloOrbita"
          loading="lazy"
          data-aos="zoom-in"
        />
      </div>
      <div class="d-flex flex-wrap justify-content-center">
        <div class="col-lg-6 d-flex flex-column justify-content-center">
          <h1 data-aos="fade-up">
            El nuevo estándar en creaciones en aplicaciones
          </h1>
          <p data-aos="fade-up" data-aos-delay="200">
            Con Orbita obtienes una vista 360 grados de tu negocio
          </p>
          <div class="d-flex d-none d-sm-none d-md-block">
            <a
              class="btn btn-info p-3 boton mx-2"
              href="https://tienda.orbita.com.co/"
              target="_blank"
              aria-label="Ir a la tienda de Orbita"
              data-aos="zoom-in"
              data-aos-delay="400"
              >TIENDA</a
            >
            <a
              class="btn btn-info p-3 boton"
              href="https://app.orbita.com.co/"
              target="_blank"
              aria-label="Ir a la aplicación de Orbita"
              data-aos="zoom-in"
              data-aos-delay="600"
              >APP</a
            >
          </div>
        </div>
        <div
          class="col-8 col-lg-4 d-flex flex-column align-items-center justify-content-center"
        >
          <img
            class="img-1"
            src="https://static.orbita.com.co/public/general/images/Orbita_Banner.png"
            alt="computador con amarillo y morado"
            loading="lazy"
            data-aos="fade-up-right"
            data-aos-delay="400"
          />
          <div class="d-flex d-block d-sm-block d-md-none">
            <a
              class="btn btn-info p-3 boton mx-2"
              href="https://tienda.orbita.com.co/"
              target="_blank"
              aria-label="Ir a la tienda de Orbita"
              data-aos="zoom-in"
              data-aos-delay="1000"
              >TIENDA</a
            >
            <a
              class="btn btn-info p-3 boton"
              target="_blank"
              href="https://app.orbita.com.co/"
              aria-label="Ir a la aplicación de Orbita"
              data-aos="zoom-in"
              data-aos-delay="1200"
              >APP</a
            >
          </div>
        </div>
      </div>
    </section>
    <!-- <transition name="fade">
    </transition> -->

    <!-- Sección de Tecnologías -->
    <section class="d-flex flex-column my-5">
      <h1 class="my-lg-5">Tecnologías</h1>
      <HexagonGrid />
      <!-- Contenido de la sección Tecnologías -->
    </section>

    <!-- Sección de Aliados -->
    <section class="d-flex flex-column my-5">
      <h1 class="my-lg-5">Participaciones</h1>
      <CardComponent />
    </section>

    <!-- Sección de Contactanos -->
    <section class="d-flex flex-column my-5">
      <OrbitaContactanos class="mt-4" />
    </section>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import HexagonGrid from "../components/HexagonGrid.vue";
import CardComponent from "@/components/CardParticipaciones.vue";
import OrbitaContactanos from "./OrbitaContactanos.vue";

export default {
  name: "OrbitaInicio",
  components: {
    HexagonGrid,
    CardComponent,
    OrbitaContactanos,
  },
  mounted() {
    AOS.init({
      duration: 1000, // Duración en milisegundos
      once: false, // Si la animación debe repetirse al hacer scroll hacia arriba
    });
  },
};
</script>

<style scoped>
.scroll-container {
  height: 80vh;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

section {
  scroll-snap-align: start;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.boton {
  font-family: "Uniform Rounded", sans-serif;
  font-style: normal;
  font-weight: 50;
  font-size: 20px;
  color: #ffcc01;
  background: rgba(25, 1, 63, 0.7);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid #ffcc01;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  margin-top: 1ch;
  margin-bottom: 1ch;
  transition: all 0.3s ease;
}

.boton:hover {
  background-color: #ffcc01;
  color: #19013f;
  border: none;
  box-shadow: 0px 4px 12px rgba(255, 204, 1, 0.5);
  transform: translateY(-2px);
}

/* Móvil */
@media screen and (max-width: 624px) {
  .logo-orbita {
    width: 250px;
  }

  .img-1 {
    width: 200px;
  }

  .boton {
    font-size: 10px;
  }
}

/* Tablet */
@media screen and (min-width: 625px) and (max-width: 1279px) {
  .logo-orbita {
    width: 350px;
  }
}

/* Portátil */
@media screen and (min-width: 1280px) and (max-width: 1919px) {
  .logo-orbita {
    width: 500px;
  }

  h1 {
    font-size: 50px;
  }

  h5 {
    font-size: 30px;
  }
}

/* Escritorio */
@media screen and (min-width: 1920px) {
  .logo-orbita {
    width: 500px;
  }

  h1 {
    font-size: 50px;
  }

  h5 {
    font-size: 30px;
  }
}
</style>
