<template>
  <section class="d-flex flex-column p-3 py-4">
    <div class="d-flex flex-wrap justify-content-center align-items-center">
      <!-- primera card  -->
      <div class="col-lg-4 card p-3 d-flex justify-content-center align-items-center">
        <div class="d-flex justify-content-center align-items-center">
          <div class="pe-2">
            <img src="https://static.orbita.com.co/public/general/images/icono_admin_empresarial.png"
              alt="Administracion empresarial" />
          </div>
          <div>
            <h2>Administración Empresarial</h2>
            <p>
              Optimizamos procesos y decisiones estratégicas.
            </p>
          </div>
        </div>
      </div>
      <!-- segunda card -->
      <div class="col-lg-4 card p-3 d-flex justify-content-center align-items-center">
        <div class="d-flex justify-content-center align-items-center">
          <div class="pe-2">
            <img class="" src="https://static.orbita.com.co/public/general/images/icono_marketin_y_diseño.png"
              alt="marketing y diseño" />
          </div>
          <div>
            <h2>Marketing y Diseño</h2>
            <p>
              Destaca con estrategias digitales y diseño atractivo.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-wrap justify-content-center align-items-center">
      <!-- tercera card -->
      <div class="col-lg-3 card p-3 d-flex justify-content-center align-items-center">
        <div class="d-flex justify-content-center align-items-center">
          <div class="pe-2">
            <img src="https://static.orbita.com.co/public/general/images/icono_nube.png" alt="nube" />
          </div>
          <div>
            <h2>Nube</h2>
            <p>
              Garantizamos una disponibilidad del 100% para tus datos
              empresariales.
            </p>
          </div>
        </div>
      </div>
      <!-- cuarta card -->
      <div class="col-lg-3 card p-3 d-flex justify-content-center align-items-center">
        <div class="d-flex justify-content-center align-items-center">
          <div class="pe-2">
            <img src="https://static.orbita.com.co/public/general/images/icono_app_medida.png" alt="App a media" />
          </div>
          <div>
            <h2>App a Medida</h2>
            <p>
              Aplicaciones personalizadas para mejorar tu productividad y
              experiencia del usuario.
            </p>
          </div>
        </div>
      </div>
      <!-- quinta card -->
      <div class="col-lg-3 card p-3 d-flex justify-content-center align-items-center">
        <div class="d-flex justify-content-center align-items-center">
          <div class="pe-2">
            <img src="https://static.orbita.com.co/public/general/images/icono_seguirdad.png" alt="seguridad" />
          </div>
          <div>
            <h2>Seguridad</h2>
            <p>
              Protegemos tus activos digitales con medidas de seguridad sólidas.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-wrap justify-content-center align-items-center">
      <!-- sexta carta -->
      <div class="col-lg-4 card p-3 d-flex justify-content-center align-items-center">
        <div class="d-flex justify-content-center align-items-center">
          <div class="pe-2">
            <img src="https://static.orbita.com.co/public/general/images/icono_database.png" alt="icono de database" />
          </div>
          <div class="d-flex flex-column justify-content-center align-items-center">
            <h2>Base De Datos</h2>
            <p>
              Organizamos y gestionamos tu información de manera eficiente.
            </p>
          </div>
        </div>
      </div>
      <!-- septima card -->
      <div
        class="col-lg-4 card p-3 d-flex justify-content-center align-items-center d-flex justify-content-center align-items-center ">
        <div class="d-flex justify-content-between align-items-center">
          <div class="pe-2">
            <img src="https://static.orbita.com.co/public/general/images/icono_asesoria_y_soporte.png"
              alt="Asesoria y soporte" />
          </div>
          <div>
            <h2>Asesoría y Soporte</h2>
            <p>
              Resolvemos tus desafíos tecnológicos.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'OrbitaServicios'
}
</script>


<style scoped>
.card {
  border-radius: 33px;
  background: rgba(25, 1, 63, 0.7);
  /* Fondo transparente para glassmorfismo */
  backdrop-filter: blur(10px);
  /* Efecto borroso de glassmorfismo */
  -webkit-backdrop-filter: blur(10px);
  color: #f6f7f9;
  width: 370px;
  height: 200px;
  margin: 10px;
  border: none;

  /* Neomorfismo: sombras suaves para crear un efecto en relieve */
  box-shadow: 8px 8px 15px rgba(0, 0, 0, 0.25),
    -8px -8px 15px rgba(255, 255, 255, 0.1);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3),
    -10px -10px 20px rgba(255, 255, 255, 0.15);
}

/* .title-services {
  color: #ffcc01;
  font-size: 24px;
  font-family: "Uniform Rounded";
  margin-bottom: 10px;
}

.subtitle-services {
  color: #f6f7f9;
  font-family: "TT Hazelnuts Trl";
  font-size: 18px;
} */

.section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

@media screen and (max-width: 992px) {
  .card {
    width: 320px;
    height: 180px;
  }
}

@media screen and (max-width: 768px) {
  .section {
    flex-direction: column;
  }

  .card {
    width: 300px;
    height: auto;
  }
}
</style>

