<template>
  <div class="hexagon-grid">
    <!-- Hexágonos generados dinámicamente -->
    <div class="hexagon" v-for="(tech, index) in technologies" :key="index" :title="tech.name"  >
      <div class="hex-content" >
        <img :src="tech.logo" :alt="tech.name" />
      </div>
    </div>
  </div>
</template>
  
<script>
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  data() {
    return {
      technologies: [
        { name: 'Angular', logo: 'https://cdn.worldvectorlogo.com/logos/angular-icon.svg' },
        { name: 'React', logo: 'https://cdn.worldvectorlogo.com/logos/react-2.svg' },
        { name: 'Spring Boot', logo: 'https://cdn.worldvectorlogo.com/logos/spring-3.svg' },
        { name: 'Prime', logo: 'https://www.primefaces.org/wp-content/uploads/2018/05/primetek_logo.png' },
        { name: 'Docker', logo: 'https://cdn.worldvectorlogo.com/logos/docker.svg' },
        { name: 'Kubernetes', logo: 'https://cdn.worldvectorlogo.com/logos/kubernets.svg' },
        { name: 'Jenkins', logo: 'https://cdn.worldvectorlogo.com/logos/jenkins-1.svg' },
        { name: 'Git', logo: 'https://cdn.worldvectorlogo.com/logos/git-icon.svg' },
        { name: 'NPM', logo: 'https://cdn.worldvectorlogo.com/logos/npm.svg' },
        { name: 'Maven', logo: 'https://maven.apache.org/images/maven-logo-black-on-white.png' },
        { name: 'Azure DevOps', logo: 'https://cdn.worldvectorlogo.com/logos/azure-2.svg' },
        { name: 'Linux', logo: 'https://cdn.worldvectorlogo.com/logos/tux.svg' },
        { name: 'Node.js', logo: 'https://cdn.worldvectorlogo.com/logos/nodejs-icon.svg' },
        { name: 'Enterprise Edition (EE)', logo: 'https://cdn.worldvectorlogo.com/logos/java-14.svg' },
        { name: 'Grafana', logo: 'https://cdn.worldvectorlogo.com/logos/grafana.svg' },
      ],
    };
  },
  mounted() {
    AOS.init({
      duration: 1000, // Duración en milisegundos
      once: false, // Si la animación debe repetirse al hacer scroll hacia arriba
    });
  },
};
</script>
  
<style scoped>
/* Contenedor de la cuadrícula hexagonal */
.hexagon-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 20px; /* Ajustar espaciado */
  justify-content: center; /* Centrar horizontalmente */
  align-items: center; /* Centrar verticalmente */
  max-width: 90%; /* Evitar desbordamiento lateral */
}

/* Hexágono */
.hexagon {
  position: relative;
  width: 120px;
  /* Ajustar tamaño */
  height: 138px;
  /* Mantener proporción */
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s ease;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), inset 0 -2px 6px rgba(255, 255, 255, 0.1);
}

/* Contenido del hexágono */
.hex-content {
  width: 80%;
  /* Proporción ajustada */
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  backdrop-filter: blur(12px);
  box-shadow: inset 0 2px 4px rgba(255, 255, 255, 0.5);
}

.hex-content img {
  width: 60%;
  /* Imagen adaptativa */
  height: auto;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.hex-content:hover img {
  transform: scale(1.2);
}

/* Animación al hacer hover */
.hexagon:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), inset 0 -4px 10px rgba(255, 255, 255, 0.2);
  cursor: pointer;
}

/* Media queries para responsividad */
@media (max-width: 768px) {
  .hexagon {
    width: 100px;
    height: 115px;
  }

  .hex-content {
    width: 65%;
    height: 65%;
  }

  
}

@media (max-width: 480px) {
  .hexagon-grid {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 0px;
  }

  .hexagon {
    width: 90px;
    height: 104px;
  }

  .hex-content {
    width: 60%;
    height: 60%;
  }

}
</style>
  