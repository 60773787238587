<template>
  <div class="container">
    <div class="row ">
      <div class="App">
        <VistaGeneral />
      </div>
    </div>
  </div>
</template>

<script>

import VistaGeneral from "./VistaGeneral.vue";

export default {
  name: "App",
  components: {
    VistaGeneral,
  },
};
</script>

<style>

@import '~bootstrap-icons/font/bootstrap-icons.css';

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Uniform Rounded", sans-serif;
}

p {
  font-family: "TT Hazelnut", sans-serif;
}

label {
  font-family: "TT Hazelnut", sans-serif;
}

section{ 
  width: 100%; 
  height: 100%;
}

#app {
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 80vh;
}

header {
  flex: none; 
}

main {
  overflow: hidden;
  flex: 1;
  display: grid;
}

footer {
  flex: none; 
  color: azure;
}

/* claro tema */
/* body {
  background-image: radial-gradient(circle at 20% 20%, #19191A, transparent 50%),
                    radial-gradient(circle at 80% 80%, #19191A, transparent 50%);
  background-color: #19013F;
  background-size: cover;
  color: #F6F7F9;
} */

body {
  background: radial-gradient(circle at center, rgba(255, 255, 255, 0.03), transparent), 
              linear-gradient(135deg, #19013F, #19191A);
  color: #F6F7F9;
  background-size: cover;
}

body::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.02) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.02) 50%, rgba(255, 255, 255, 0.02) 75%, transparent 75%, transparent);
  background-size: 56px 56px;
  z-index: -1;
}


</style>
