<template>
  <section>

    <div class="d-flex flex-column align-items-center pt-lg-5">
      <div class="d-flex flex-wrap align-items-center justify-content-center">
        <!-- Duwan Sierra -->
        <div class="card d-flex align-items-center">
          <div>
            <svg width="150" height="170" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink">
              <circle cx="100" cy="100" r="100" fill="url(#pattern1)" />
              <defs>
                <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
                  <use xlink:href="#image1" transform="translate(0 -0.133663) scale(0.000990099)" />
                </pattern>
                <image id="image1" width="1010" height="1280"
                  xlink:href="https://static.orbita.com.co/public/general/images/1550588487683.jpg" />
              </defs>
            </svg>
          </div>
  
          <div class="pt-2">
            <h2 class="text-center p-0 m-0">Duwan Sierra</h2>
            <p class="text-center p-0 m-0">Arquitecto</p>
          </div>
  
          <div>
            <a target="_blank" href="https://www.linkedin.com/in/duwansierra/"><i class="fa-brands fa-linkedin p-2 py-3 text-white" style="font-size: 4ch;" ></i></a>
            <a target="_blank" href="https://www.instagram.com/_lcnea_/"><i class="fa-brands fa-instagram p-2 text-white" style="font-size: 4ch;"></i></a>
          </div>
  
        </div>
  
        <!-- Johnson Torres -->
        <div class="card d-flex align-items-center">
          <div>
            <svg width="150" height="170" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink">
              <circle cx="100" cy="100" r="100" fill="url(#pattern2)" />
              <defs>
                <pattern id="pattern2" patternContentUnits="objectBoundingBox" width="1" height="1">
                  <use xlink:href="#image2" transform="translate(0 -0.133663) scale(0.000990099)" />
                </pattern>
                <image id="image2" width="1010" height="1280"
                  xlink:href="https://static.orbita.com.co/public/general/images/1682008815606.jpg" />
              </defs>
            </svg>
          </div>
  
          <div class="pt-2">
            <h2 class="text-center p-0 m-0">Johnson Torres</h2>
            <p class="text-center p-0 m-0">Desarrollador</p>
          </div>
  
          <div>
            <a target="_blank" href="https://www.linkedin.com/in/johnsontorres/"><i class="fa-brands fa-linkedin p-2 py-3 text-white" style="font-size: 4ch;" ></i></a>
            <a target="_blank" href="https://www.instagram.com/jhd_torres/"><i class="fa-brands fa-instagram p-2 text-white" style="font-size: 4ch;"></i></a>
          </div>
        </div>
  
        <!-- Alix Meneses -->
        <div class="card d-flex align-items-center">
          <div>
            <svg width="150" height="170" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink">
              <circle cx="100" cy="100" r="100" fill="url(#pattern3)" />
              <defs>
                <pattern id="pattern3" patternContentUnits="objectBoundingBox" width="1" height="1">
                  <use xlink:href="#image3" transform="translate(0 -0.133663) scale(0.000990099)" />
                </pattern>
                <image id="image3" width="1010" height="1280"
                  xlink:href="https://static.orbita.com.co/public/general/images/1682008815605.jpg" />
              </defs>
            </svg>
          </div>
          <div class="pt-2">
            <h2 class="text-center p-0 m-0">Alix Meneses</h2>
            <p class="text-center p-0 m-0">Desarrolladora</p>
          </div>
          <div>
            <a target="_blank" href="https://www.linkedin.com/feed/"><i class="fa-brands fa-linkedin p-2 py-3 text-white" style="font-size: 4ch;"></i></a>
            <a target="_blank" href="https://www.instagram.com/alix_meneses/"><i class="fa-brands fa-instagram p-2 text-white" style="font-size: 4ch;"></i></a>
          </div>
        </div>
      </div>
  
      <div class="d-flex flex-wrap align-items-center justify-content-center">
        <!-- Dayan Quijano -->
        <div class="card d-flex align-items-center">
          <div>
            <svg width="150" height="170" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink">
              <circle cx="100" cy="100" r="100" fill="url(#pattern4)" />
              <defs>
                <pattern id="pattern4" patternContentUnits="objectBoundingBox" width="1" height="1">
                  <use xlink:href="#image4" transform="translate(0 -0.133663) scale(0.000990099)" />
                </pattern>
                <image id="image4" width="1010" height="1280"
                  xlink:href="https://static.orbita.com.co/public/general/images/1575582559498.jpg" />
              </defs>
            </svg>
          </div>
  
          <div class="pt-2">
            <h2 class="text-center m-0 p-0">Dayan Quijano</h2>
            <p class="text-center m-0 p-0">Marketin</p>
          </div>
  
          <div>
            <a target="_blank" href="https://www.linkedin.com/in/dayan-stephany-quijano-guti%C3%A9rrez-112928179/"><i class="fa-brands fa-linkedin p-2 py-3 text-white" style="font-size: 4ch;" ></i></a>
            <a target="_blank" href="https://www.instagram.com/dayanquijano/"><i class="fa-brands fa-instagram p-2 text-white" style="font-size: 4ch;"></i></a>
          </div>
        </div>
  
        <!-- Diana Velez -->
        <div class="card d-flex align-items-center">
          <div>
            <svg width="150" height="170" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink">
              <circle cx="100" cy="100" r="100" fill="url(#pattern5)" />
              <defs>
                <pattern id="pattern5" patternContentUnits="objectBoundingBox" width="1" height="1">
                  <use xlink:href="#image5" transform="translate(0 -0.133663) scale(0.000990099)" />
                </pattern>
                <image id="image5" width="1010" height="1280"
                  xlink:href="https://static.orbita.com.co/public/general/images/1550588487684.jpg" />
              </defs>
            </svg>
          </div>
  
          <div class="pt-3">
            <h2 class="text-center m-0 p-0">Diana Velez</h2>
            <p class="text-center m-0 p-0">Diseño</p>
          </div>
  
          <div>
            <a target="_blank" href="https://www.linkedin.com/in/diana-velez-garcia-258b7a15b/ "><i class="fa-brands fa-linkedin p-2 py-3 text-white" style="font-size: 4ch;" ></i></a>
            <a target="_blank" href="https://www.instagram.com/vlez.c/"><i class="fa-brands fa-instagram p-2 text-white" style="font-size: 4ch;"></i></a>
          </div> 
        </div>
      </div>
    </div>

  </section>
</template>

<script>
export default {
  name: "OrbitaEquipo",
};
</script>

<style scoped>
.card {
  background: rgba(25, 1, 63, 0.7); /* Fondo transparente para glassmorfismo */
  backdrop-filter: blur(10px); /* Efecto borroso de glassmorfismo */
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid #8f09c8;
  border-radius: 33px;
  margin: 10px;
  color: aliceblue;
  height: 300px;
  width: 220px;
  /* Neomorfismo: sombras suaves para crear un efecto en relieve */
  box-shadow: 8px 8px 15px rgba(0, 0, 0, 0.25),
              -8px -8px 15px rgba(255, 255, 255, 0.1);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3),
              -10px -10px 20px rgba(255, 255, 255, 0.15);
}

p {
  color: #ffcc01;
}

h2 {
  font-size: 1rem;
  padding: 2%;
}
</style>
