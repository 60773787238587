<template>
  <section class="d-flex flex-wrap align-items-center justify-content-center">
    <!-- Plan 1 -->
    <div class="col-lg-3 p-4 m-3 d-flex flex-column align-items-center justify-content-center">
      <div class="card p-3">
        <div class="rectangle-yellow p-3 mt-4">Plan</div>

        <div>
          <h1 class="mx-4 pt-5 text-center">Básico</h1>
          <!-- <p class="text-center mx-4 pt-0">0%</p> -->
        </div>

        <div>
          <p class="mx-4 text-plans contenido">
            Nuestro Plan Básico es perfecto para aquellos que están buscando dar sus primeros pasos en la digitalización.
            Ofrece una solución de software a medida con servicios esenciales de administración empresarial y asesoría.
          </p>
        </div>

        <div>
          <div class="d-flex mx-3 ">
            <i class="fa-solid fa-circle-check col-2 text-center "></i>
            <p class="col-10 subtitle-plans">
              Administración Empresarial
            </p>
          </div>

          <div class="d-flex mx-3">
            <i class="fa-solid fa-circle-xmark col-2 text-center"></i>
            <p class="col-10 subtitle-plans">
              Marketing y Diseño
            </p>
          </div>

          <div class="d-flex mx-3">
            <i class="fa-solid fa-circle-xmark col-2 text-center "></i>
            <p class="col-10 subtitle-plans">
              Seguridad en la Nube
            </p>
          </div>

          <div class="d-flex mx-3">
            <i class="fa-solid fa-circle-xmark col-2 text-center "></i>
            <p class="col-10 subtitle-plans">
              App a la Medida
            </p>
          </div>

          <div class="d-flex mx-3">
            <i class="fa-solid fa-circle-check col-2 text-center "></i>
            <p class="col-10 subtitle-plans">
              Seguridad
            </p>
          </div>

          <div class="d-flex mx-3">
            <i class="fa-solid fa-circle-xmark col-2 text-center "></i>
            <p class="col-10 subtitle-plans">
              Bases de Datos
            </p>
          </div>

          <div class="d-flex mx-3">
            <i class="fa-solid fa-circle-xmark col-2 text-center"></i>
            <p class="col-10 subtitle-plans">
              Asesoría y Soporte
            </p>
          </div>
        </div>
      </div>

      <div>
        <a target="_blank" href="https://app.orbita.com.co/"><button type="button" class="btn btn-primary boton"
            style="left: 593px;">Demo</button></a>
      </div>
    </div>

    <!-- Plan 2 -->
    <div class="col-lg-3 p-4 m-3 d-flex flex-column align-items-center justify-content-center">
      <div class="card p-3">
        <div class="rectangle-puerple text-dark p-3 mt-4">Plan</div>

        <div>
          <h1 class="mx-4 pt-5 text-center">Premium</h1>
          <!-- <p class="text-center mx-4 pt-0">/Mes</p> -->
        </div>

        <div>
          <p class="mx-4 text-plans">
            Nuestro Plan Premium es la elección definitiva para empresas que desean una experiencia de software a medida
            excepcional, incluyendo servicios avanzados de nube, seguridad y asesoría personalizada.
          </p>
        </div>

        <div>
          <div class="d-flex mx-3">
            <i class="fa-solid fa-circle-check col-2 text-center"></i>
            <p class="col-10 subtitle-plans">
              Administración Empresarial
            </p>
          </div>

          <div class="d-flex mx-3">
            <i class="fa-solid fa-circle-xmark col-2 text-center"></i>
            <p class="col-10 subtitle-plans">
              Marketing y Diseño
            </p>
          </div>

          <div class="d-flex mx-3">
            <i class="fa-solid fa-circle-check col-2 text-center"></i>
            <p class="col-10 subtitle-plans">
              Seguridad en la Nube
            </p>
          </div>

          <div class="d-flex mx-3">
            <i class="fa-solid fa-circle-xmark col-2 text-center"></i>
            <p class="col-10 subtitle-plans">
              App a la Medida
            </p>
          </div>

          <div class="d-flex mx-3">
            <i class="fa-solid fa-circle-check col-2 text-center"></i>
            <p class="col-10 subtitle-plans">
              Seguridad
            </p>
          </div>

          <div class="d-flex mx-3">
            <i class="fa-solid fa-circle-check col-2 text-center"></i>
            <p class="col-10 subtitle-plans">
              Bases de Datos
            </p>
          </div>

          <div class="d-flex mx-3">
            <i class="fa-solid fa-circle-check col-2 text-center"></i>
            <p class="col-10 subtitle-plans">
              Asesoría y Soporte
            </p>
          </div>
        </div>
      </div>

      <div>
        <a target="_blank" href="https://wa.me/message/DFXAEG33VKIZH1"> <button type="button"
            class="btn btn-primary boton" style="left: 922px;">Adquirir</button></a>
      </div>
    </div>

    <!-- Plan 3 -->
    <div class="col-lg-3 p-4 m-3 d-flex flex-column align-items-center justify-content-center">
      <div class="card p-3">
        <div class="rectangle-white text-dark p-3 mt-4">Plan</div>

        <div>
          <h1 class="mx-4 pt-5 text-center">Especial</h1>
          <!-- <p class="text-center mx-4 pt-0">/Mes</p> -->
        </div>

        <div>
          <p class="mx-4 text-plans">
            Si tu empresa requiere una solución completamente adaptada a sus requerimientos específicos, nuestro Plan a
            Medida ofrece una colaboración estrecha para desarrollar un software altamente personalizado.
          </p>
        </div>

        <div>
          <div class="d-flex mx-3">
            <i class="fa-solid fa-circle-check col-2 text-center "></i>
            <p class="col-10 subtitle-plans">Administración Empresarial</p>
          </div>

          <div class="d-flex mx-3">
            <i class="fa-solid fa-circle-check col-2 text-center"></i>
            <p class="col-10 subtitle-plans">Marketing y Diseño</p>
          </div>

          <div class="d-flex mx-3">
            <i class="fa-solid fa-circle-check col-2 text-center "></i>
            <p class="col-10 subtitle-plans">Seguridad en la Nube</p>
          </div>

          <div class="d-flex mx-3">
            <i class="fa-solid fa-circle-check col-2 text-center "></i>
            <p class="col-10 subtitle-plans">App a la Medida</p>
          </div>

          <div class="d-flex mx-3">
            <i class="fa-solid fa-circle-check col-2 text-center "></i>
            <p class="col-10 subtitle-plans">Seguridad</p>
          </div>

          <div class="d-flex mx-3">
            <i class="fa-solid fa-circle-check col-2 text-center "></i>
            <p class="col-10 subtitle-plans">Base De Datos</p>
          </div>

          <div class="d-flex mx-3">
            <i class="fa-solid fa-circle-check col-2 text-center"></i>
            <p class="col-10 subtitle-plans">Asesoría y Soporte</p>
          </div>

        </div>

      </div>
      <div>
        <a target="_blank" href="https://wa.me/message/DFXAEG33VKIZH1"><button href="https://wa.me/message/DFXAEG33VKIZH1"
            type="button" class="btn btn-primary boton" style="left: 1250px;">Cotizar</button></a>
      </div>

    </div>

  </section>
</template>

<script>
export default {
  name: "OrbitaPlanes",
};
</script>

<style scoped>
.card {
  background: rgba(25, 1, 63, 0.7);
  /* Fondo transparente para glassmorfismo */
  backdrop-filter: blur(10px);
  /* Efecto borroso de glassmorfismo */
  -webkit-backdrop-filter: blur(10px);
  border-radius: 33px;
  margin-bottom: 2ch;
  color: aliceblue;
  /* Neomorfismo: sombras suaves para crear un efecto en relieve */
  box-shadow: 8px 8px 15px rgba(0, 0, 0, 0.25),
    -8px -8px 15px rgba(255, 255, 255, 0.1);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3),
    -10px -10px 20px rgba(255, 255, 255, 0.15);
}

.boton {
  font-family: "Uniform Rounded", sans-serif;
  font-style: normal;
  font-weight: 50;
  font-size: 20px;
  color: #ffcc01;
  background: rgba(25, 1, 63, 0.7);
  /* Fondo transparente para glassmorfismo */
  backdrop-filter: blur(10px);
  /* Efecto borroso de glassmorfismo */
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid #ffcc01;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  margin-top: 1ch;
  margin-bottom: 1ch;
  transition: all 0.3s ease;
}

.boton:hover {
  background-color: #ffcc01;
  color: #19013f;
  border: none;
  box-shadow: 0px 4px 12px rgba(255, 204, 1, 0.5);
  transform: translateY(-2px);
}

.text-plans {
  font-family: "Uniform Rounded" !important;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;

}

.subtitle-plans {
  font-family: "Uniform Rounded" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
}

.rectangle-yellow {
  position: absolute;
  top: -7px;
  left: -28px;
  width: 124px;
  height: 48px;
  background-color: #ffcc01;
  color: black;
  font-size: 15px;
  font-family: TT Hazelnuts Trl;
  font-weight: 700;
  word-wrap: break-word;
  clip-path: polygon(0% 0%, 63% 0, 81% 51%, 100% 100%, 0% 100%);
}

.rectangle-puerple {
  position: absolute;
  top: -7px;
  left: -28px;
  width: 124px;
  height: 48px;
  background-color: #8f09c8;
  color: black;
  font-size: 15px;
  font-family: TT Hazelnuts Trl;
  font-weight: 700;
  word-wrap: break-word;
  clip-path: polygon(0% 0%, 63% 0, 81% 51%, 100% 100%, 0% 100%);
}

.rectangle-white {
  position: absolute;
  top: -7px;
  left: -28px;
  width: 124px;
  height: 48px;
  background-color: #f6f7f9;
  color: black;
  font-size: 15px;
  font-family: TT Hazelnuts Trl;
  font-weight: 700;
  word-wrap: break-word;
  clip-path: polygon(0% 0%, 63% 0, 81% 51%, 100% 100%, 0% 100%);
}

.fa-circle-check {
  color: #ffcc01;
}

@media (min-width: 1033px) and (max-width: 1187px) {
  .card {
    width: 246px;
  }
}</style>
