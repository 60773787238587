import { createRouter, createWebHistory } from 'vue-router'
import OrbitaInicio from '@/views/OrbitaInicio.vue'
import OrbitaEquipo from '@/views/OrbitaEquipo.vue'
import OrbitaContactanos from '@/views/OrbitaContactanos.vue'
import OrbitaQuieness from '@/views/OrbitaQuieness.vue'
import OrbitaServicios from '@/views/OrbitaServicios.vue'
import OrbitaPlanes from '@/views/OrbitaPlanes.vue'

const routes = [
  { path: '/', name: 'Inicio', component: OrbitaInicio },
  { path: '/equipo', name: 'Equipo', component: OrbitaEquipo },
  { path: '/contactanos', name: 'Contactanos', component: OrbitaContactanos },
  { path: '/quienes-somos', name: 'Quienes Somos', component: OrbitaQuieness },
  { path: '/servicios', name: 'Servicios', component: OrbitaServicios },
  { path: '/planes', name: 'Planes', component: OrbitaPlanes },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
